body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {height: 100%}
#root {height: 99%}
.marginWrap {
  margin : 10px;
}
.topNav {
  margin: 10px;
}
.App-link {
  color: #61dafb;
}
header {
  /* text-align: center; */
  padding-left: 5%;
  background-color: #0b6754;
}
header img {
  max-height: 120px;
}
header a:hover {
  color: white;
  text-decoration:none;
}
header a {
  color: white;
  text-decoration:none;
}
h1#topH1 {
  margin: 0;
  padding: 20px;
}

img.imgButton {
  width: 100%;
  margin: 10px;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*style="page-break-inside:avoid"

*/

#WordSection1{
    /* margin-left:30%; 
    margin-right:30%; */
}

#MsoNormal_title{
    text-align: center; 
    line-height: 28.0pt;
    font-size:26.0pt;
    font-family:標楷體;
}

#MsoNormal_date{
    text-align: right;
    font-size:14.0pt;
    font-family:標楷體;
}

#MsoNormalTable{
    width:100%;
    border-collapse:collapse;
    border:none;
    border-spacing:10px;
    padding:10px;
}

#building_addreee_btd1{
    width:19%;
    border:solid
    windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
}

#building_addreee_btd2, #building_addreee_atd1, #land_address_btd2, #land_address_atd1
{
    width:9%;
    border:solid windowtext 1.0pt;
    border-left: none;
    padding:0cm 1.4pt 0cm 1.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
}

#building_addreee_btd3{
    width:70%;
    border:solid windowtext 1.0pt;
    border-left:none;
    padding:0cm 1.4pt 0cm 1.4pt;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
    vertical-align:top;
}

#building_addreee_atd2, #land_address_btd3, #land_address_atd2{
    width:70%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
    vertical-align:top;
}

#land_address_btd1, #license_number_td1, #copy_things_td1, #attachment_data_td1,
#apply_person_td1, #agent_person_td1{
    width:19%;
    border:solid windowtext 1.0pt;
    border-top:none;
    padding:0cm 1.4pt 0cm 1.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
}

#apply_usage_td1{
    width:19%;
    border:solid windowtext 1.0pt;
    border-top:none;
    padding:0cm 1.4pt 0cm 1.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
    height:36.5pt
}

#apply_usage_td2{
    width:80%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    line-height:28.0pt;
    font-size:18.0pt;
    font-family:Wingdings;
    vertical-align:top;
    height:36.5pt;
}

#check_box_1, #check_box_2, #check_box_3, #check_box_4,
#check_box_5, #check_box_6, #check_box_7, #check_box_8,
#check_box_9, #check_box_10, #check_box_11, #check_box_12,
#check_box_13, #check_box_14{
    margin-left:18.0pt;
    text-indent:-18.0pt;
    line-height:28.0pt;
    font-size: 18.0pt;
    font-family: Wingdings;
}

#text_1, #text_2, #text_3, #text_4, #text_5, #text_6,
#text_7, #text_8, #text_9, #text_10, #text_11, #text_12,
#text_13, #text_14, #text_15, #text_16, #text_17, #text_18,
#text_20, #text_21, #text_22, #text_23, #text_24,#text_25,
#text_26,#text_27{
    font-size:16.0pt;
    font-family:標楷體;
}
#text_19{
    text-decoration:underline;
    font-family:標楷體;
    font-size:16.0pt;
}

#license_number_td2, #license_number_td3, #copy_things_td2, #attachment_data_td2{
    width:80%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    line-height:28.0pt;
    font-size:14.0pt;
    vertical-align:top;
    font-family:標楷體;
}

#apply_person_name1, #apply_person_id1, #agent_person_name1, #agent_person_id1{
    width:15%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:49.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
}

#apply_person_name2, #apply_person_id2, #agent_person_name2, #agent_person_id2{
    width:14%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:49.4pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    font-size:14.0pt;
}

#apply_person_address1, #apply_person_phone1, #apply_person_fax1, #agent_person_address1,
#agent_person_phone1, #agent_person_fax1{
    width:7%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:49.4pt;
    text-align:center;
    line-height:28.0pt;
    font-size:14.0pt;
    font-family:標楷體;
}

#apply_person_address2, #agent_person_address2{
    width:43%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:49.4pt;
    vertical-align:middle;
    line-height:28.0pt;
    font-size:14.0pt;
}

#apply_person_phone2, #agent_person_phone2{
    width:18%;
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:40.0pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    font-size:14.0pt;
}

#apply_person_fax2, #agent_person_fax2{
    border-top:none;
    border-left:none;
    border-bottom:solid windowtext 1.0pt;
    border-right:solid windowtext 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:40pt;
    vertical-align:middle;
    line-height:28.0pt;
    font-size:14.0pt;
}
/*body {
    padding: 0px;
    margin: 0px;
}

div{
    padding: 10px 20px;
}*/

#table_copy{
    margin-left:auto; 
    margin-right:auto;
    margin-top: 50px;
    border-collapse: collapse;
    font-size: 20px;
}

#table_building_address_before, #table_building_address_after, #table_land_address_before, 
#table_land_address_after{
    border-collapse: collapse;
    width: 100%;
    /* text-align: right; */
}

#copy_things_before, #copy_things_after{
    border-collapse: collapse;
}

#attachment_data_td2 {
    width: 20%;
}

#building_address, #land_address, #copy_things, #attachment_data, #agent_person{
    width: 10%;
}

div{
    layout-grid: 18.0pt;
}
#public_land_consolidation{
    border-spacing: 0;
    padding: 0;
    border-collapse: collapse;
    border: medium none;
    margin-left: 1.4pt;
}
#PublicLandAppSub1_title{
    vertical-align: top;
    width: 746px; 
    height: 35.55pt; 
    border: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
}
#title1{
    text-indent: 8.5pt; 
    line-height: 30.0pt; 
    margin-bottom: 6.0pt;
    font-size: 18.0pt; 
    font-family: 標楷體; 
}
#title2{
    font-family: 標楷體;
}
#public, #private{
    width: 36px;
    vertical-align: top;
    height: 29.3pt;
    border-left: 1.0pt solid windowtext; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: justify; 
    text-justify: distribute-all-lines;
    font-size: 13.0pt; 
    font-family: 標楷體;
    text-align: center;
}
#public_land_mark{
    widows: 22px;
    vertical-align: top;
    height: 29.3pt;
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: justify; 
    text-justify: distribute-all-lines; 
    line-height: 12.0pt;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#public_district1, #public_segment1, #public_smallsegment1, 
#public_land_number1, #public_land_eye1{
    width:75px;
    height: 29.3pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#public_district2, #public_segment2, #public_smallsegment2, 
#public_land_number2, #public_land_eye2, #public_area2, 
#public_using_partitions2, #public_owner2{
    width:273px;
    height: 29.3pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 12.0pt; 
    font-family: 標楷體;
    vertical-align: top;
}
#private_land_mark{
    width:23px;
    vertical-align: top;
    height: 29.3pt;
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: justify; 
    text-justify: distribute-all-lines; 
    line-height: 12.0pt;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#private_district1, #private_segment1, #private_smallsegment1, 
#private_land_number1, #private_land_eye1{
    width:77px;
    height: 29.3pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}

#private_district2, #private_segment2, #private_smallsegment2, 
#private_land_number2, #private_land_eye2, #private_area2, 
#private_using_partitions2, #private_owner2{
    width: 204px;
    height: 29.3pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 12.0pt; 
    font-family: 標楷體;
    vertical-align: top;
}

#public_area1{
    width: 97px;
    height: 30.5pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt;  
}
#private_area1{
    width: 110px;
    height: 30.5pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt;  
}
.text1{
    font-family: 標楷體;
}
.text2{
    font-family: Arial,sans-serif
}
#public_using_partitions1, #public_owner1{
    width: 97px;
    height: 30.5pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt;  
    font-family: 標楷體;
}
#private_using_partitions1, #private_owner1{
    width: 110px;
    height: 30.5pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt;  
    font-family: 標楷體;
}
#PublicLandAppSub1_apply_person, #description1{
    width: 27.0px;
    vertical-align: top;
    height: 29.3pt;
    border-left: 1.0pt solid windowtext;
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: justify; 
    text-justify: distribute-all-lines;
    font-size: 13.0pt; 
    font-family: 標楷體;
    text-align: center;
}
#PublicLandAppSub1_name1, #PublicLandAppSub1_phone1, #PublicLandAppSub1_address1{
    width: 22px;
    height: 27.4pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#PublicLandAppSub1_name2{
    width:119px;

    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#PublicLandAppSub1_birth1, #PublicLandAppSub1_id1{
    width: 95px;
    height: 27.4pt;
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#PublicLandAppSub1_birth2, #PublicLandAppSub1_id2{
    width:520px;
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}

#description2{
    width: 203px;
    height: 27.4pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: justify; 
    text-justify: distribute-all-lines; 
    /*text-indent: -.55pt; */
    line-height: 15pt; 
    margin-left: 4.4pt;
    font-size: 13.0pt; 
    font-family: 標楷體;
    vertical-align: text-top;
}
#PublicLandAppSub1_phone2, #PublicLandAppSub1_address2{
    width: 348px;
    vertical-align: top;
    height: 44.25pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#text{
    height: 83.5px;
    width: 746pt;
    border-left: 1.0pt solid windowtext; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm; 
    margin-bottom: 9.0pt;
    font-size: 13.0pt; 
    font-family: 標楷體;
    vertical-align: text-top;
}
#picture{
    width: 481px;
    vertical-align: top;
    height: 18.4pt; 
    border-left: 1.0pt solid windowtext; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#rule{

    font-family: 標楷體;
}
#PublicLandAppSub1_remark1, #P_example1{
    width: 285px;
    vertical-align: top;
    height: 18.4pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 13.0pt; 
    font-family: 標楷體;
}
#PublicLandAppSub1_remark2{
    width: 285px;
    height: 141.85pt;
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    padding-top: 0cm; 
    padding-bottom: 0cm;
    text-align: center;
    font-size: 20.0pt; 
    font-family: 標楷體;
    
}
#P_example2{
    width: 285px;
    height: 370.65pt; 
    border-left: medium none; 
    border-right: 1.0pt solid windowtext; 
    border-top: medium none; 
    border-bottom: 1.0pt solid windowtext; 
    padding-left: 1.4pt; 
    padding-right: 1.4pt; 
    /*padding-top: 0cm; */
    padding-bottom: 0cm;
    /*text-align: justify; */
    /*text-justify: inter-ideograph;*/
    font-size: 13.0pt; 
    font-family: 標楷體;
    
    vertical-align: text-top;
}

#Space_division_table{
    border-spacing: 0;
    padding: 0;
    border-collapse:collapse;
    border-top:red 2.25pt solid;
    border-right:red 2.25pt solid;
    border-left:red 2.25pt solid;
    border-bottom:red 2.25pt solid;
    width: 920;
    height: 1125px;
    color:red;
    margin-right: auto;
    margin-left: auto;
}

#Space_division_title{
    width:458;
    height:1125; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    margin-top:9.0pt;
    line-height:20.0pt;
    font-size:20.0pt;
    font-family:標楷體;
    
}

#date{
    text-align: right;
    line-height:0pt;
    font-size:12.0pt;
}

#receive_number1, #certificate_number1 {
    width:72;
    height:39px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#receive_number2, #certificate_number2{
    width:359;
    height:39px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#audit_signature{
    width:29;
    /*layout-flow:vertical-ideographic;*/
    height:182px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    margin-top:0cm;
    margin-right:5.65pt;
    margin-bottom:0cm;
    margin-left:5.65pt;
    margin-bottom:.0001pt;
    text-justify:distribute-all-lines;line-height:12.0pt;
    font-family:標楷體;
    text-align: center;
    font-size:12.0pt;
}

#instruction1, #review_allow1, #undertaker1{
    width:78;
    height:83px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:left;
    text-justify:inter-ideograph;
    line-height:12.0pt;
    font-family:標楷體;
    font-size:11.0pt;
}

#instruction2, #review_allow2, #undertaker2{
    width:166;
    height:83px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:left;
    text-justify:inter-ideograph;
    line-height:12.0pt;
    font-family:標楷體;
    font-size:11.0pt;
}


#review_project {
    width:438;
    height:18px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;
    text-justify:inter-ideograph;
    line-height:13.0pt;
    font-family:標楷體;
    font-size:10.0pt;
}

#review_result{
    width: 178;
    height:18px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;
    text-justify:inter-ideograph;
    line-height:13.0pt;
    font-family:標楷體;
    font-size:10.0pt;
}

#instruction3, #instruction5, #review_allow3, #review_allow5, #undertaker3{
    width:438;
    height:30px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:23.0pt;
    font-size:11.0pt;
    font-family:標楷體;
}

#instruction4, #instruction6, #review_allow4, #review_allow6, #undertaker4{
    width:178;
    height:30px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:23.0pt;
    font-size:11.0pt;
    font-family:標楷體;
}

#text{
    width:920;
    height:58px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm;
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-family:標楷體;
}

#attached_data1{
    width:75;
    height:83px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    margin-top:4.5pt;
    text-align:justify;text-justify:distribute-all-lines;line-height:12.0pt;
    /*position:absolute;*/
    z-index:251659264;
    left:0px;
    margin-left:-36px;
    margin-top:10px;
    width:33px;
    font-size:10pt;
    font-family:標楷體;
}

#attached_data2, #attached_data5{
    width:205;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#attached_data3, #attached_data6{
    width:178;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#attached_data4, #attached_data7{
    width:431;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:10pt;
    font-family:標楷體;
}

#apply_person, #buliding_address{
    width: 32;
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    margin-top:0cm;
    margin-right:5.65pt;
    margin-bottom:0cm;
    margin-left:5.65pt;
    margin-bottom:.0001pt;
    text-align:center;
    text-justify:distribute-all-lines;line-height:12.0pt;
    font-size:12pt;
    font-family:標楷體;
}

#name1, #idnumber1, #address1{
    width:63;
    height:44px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#name2, #idnumber2{
    width:243;
    height:44px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#birth1, #phone1{
    width:52;
    height:44px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:10.0pt;
    font-family:標楷體;
}

#birth2, #phone2{
    width:175;
    height:44px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align: center;
    line-height:12.0pt;
    font-family:標楷體;
}

#space{
    vertical-align: top;
    width:324;
    height:127px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-size:8.0pt;
    font-family:標楷體;
}

#address2{
    width:616;
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
}

#land_number1, #land_address1{
    width:63;
    height:50px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}

#land_number2, #land_address2{
    width:794;
    height:50px;
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-family:標楷體;
}

#specified_use1{
    vertical-align: top;
    width:95;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-size:11.0pt;
    font-family:標楷體;
}

#specified_use2{
    width:222;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}

#original_license_number1{
    width:113;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-size:11.0pt;
    font-family:標楷體;
}

#original_license_number2{
    width:459;
    height:51px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}

#base_area{
    width:33;
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    margin-top:0cm;
    margin-right:5.65pt;
    margin-bottom:0cm;
    margin-left:5.65pt;
    margin-bottom:.0001pt;
    text-align:center;
    text-justify:distribute-all-lines;line-height:12.0pt;
    font-size:12pt;
    font-family:標楷體;
}

#arcade1, #buliding_area1, #total1{
    width:62;
    height:85px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
}

#arcade2, #buliding_area2, #total2{
    width:222;
    border-top:medium none;
    height:85px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}

#existing_space1, #statutory_space1, #construction_rate1{
    width:84;
    height:85px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
}

#existing_space2, #statutory_space2, #construction_rate2{
    width:488;
    height:85px;
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}

#remark1{
    width:33;
    layout-flow:vertical-ideographic;
    height:152px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
}

#remark2{
    width:887;
    height:152px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-family:標楷體;
    vertical-align: top;
}

.Text1{
    line-height:14.0pt;
    font-size:10.0pt;
    font-family:標楷體;
    color:red; 
    font-size:10.0pt;
    font-family:標楷體;
}

.Text2{
    line-height:14.0pt;
    font-size:10.0pt;
    font-family:標楷體;
    color:red;
    margin-left:18.9pt;
    text-indent:-18.9pt;
    font-size:10.0pt;
    font-family:標楷體;
}

.Text3{
    line-height:14.0pt;
    font-size:10.0pt;
    font-family:標楷體;
    color:red; 
}

#table1{
    layout-grid:18.0pt; 
    
    height:1343px;
    border-top:red 2.25pt solid;
    border-right:red 2.25pt solid;
    border-left:red 2.25pt solid;
    border-bottom:red 2.25pt solid;
}
#format{
    border-spacing: 0;
    padding: 0;
    
    border-collapse:collapse;
    border:none;
    
}
#text_text_format{
    vertical-align: top;
    
    border:none;
    
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
}
#text1{
    font-size:11.0pt;
    font-family:標楷體;
    color:red;
}
.WordSection1{
    layout-grid:18.0pt;
    width:932px;
    height:1343px;
}
#table{
    border-spacing: 0;
    padding: 0;
    width:920px;
    border-collapse:collapse;
    border:medium none;
}
#title{
    border-left:2.25pt solid red; 
    border-right:2.25pt solid red; 
    border-top:2.25pt solid red; 
    width:910px;
    border-bottom:1.0pt solid red;
    height:57.4pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm
}
#text1{
    text-align:center;
    line-height:25.0pt;
    font-size:20.0pt;
    font-family:標楷體;
    color:red;
}
#text2{
    text-align:right;
    text-indent:270.0pt;
    line-height:25.0pt;
    word-break:break-all;
    font-family:標楷體;
    color:red;
}
#text3{
    vertical-align: top;
    width:910px;
    border-top:medium none;
    border-left:2.25pt solid red;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:281.35pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
}
.text4{
    margin-top:0cm;
    margin-right:6.0pt;
    margin-bottom:0cm;
    margin-left:287.9pt;
    margin-bottom:.0001pt;
    line-height:26.0pt;
    layout-grid-mode:char;
    font-size:18.0pt;
    color:red;
    font-family: 標楷體;
}
.text5{
    margin-top:0cm;
    margin-right:6.0pt;
    margin-bottom:0cm;
    margin-left:6.0pt;
    margin-bottom:.0001pt;
    text-align:justify;
    text-justify:inter-ideograph;
    text-indent:317.9pt;
    layout-grid-mode:char;
    font-size:18.0pt;
    font-family:標楷體;
    color:red;
}
#text6{
    margin-top:0cm;
    margin-right:6.0pt;
    margin-bottom:0cm;
    margin-left:6.0pt;
    margin-bottom:.0001pt;
    text-align:justify;
    text-justify:inter-ideograph;
    text-indent:252.0pt;
    layout-grid-mode:char;
    color:red;
    font-family: 標楷體;
}
#apply_person,#buliding_address{
    width:25.4pt;
    border-top:none;
    border-left:solid red 2.25pt;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    layout-flow:vertical-ideographic;
    height:22.75pt;
    margin-top:0cm;
    margin-right:5.65pt;
    margin-bottom:0cm;
    margin-left:5.65pt;
    margin-bottom:.0001pt;
    text-align:center;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#name1, #id1, #address1{
    width:49.5pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#name2, #id2{
    width:161.35pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:justify;
    text-justify:inter-ideograph;
    text-indent:15.0pt;
    line-height:12.0pt;
    font-family:標楷體;
}
#birth1, #phone1{
    width:49.5pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#birth2, #phone2{
    width:520px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:22.75pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-indent:48.0pt;
    line-height:12.0pt;
    font-family: 標楷體;
}
#address2{
    width:804px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:22.75pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:justify;
    text-justify:inter-ideograph;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#land_number1,#land_address1{
    width:49.5pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:center;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-family:標楷體;
    color:red;
}
#land_number2, #land_address2{
    width:804px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:22.75pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-family:標楷體;
}
#usage1{
    vertical-align: top;
    width:74.9pt;
    border-top:none;
    border-left:solid red 2.25pt;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    line-height:12.0pt;
    font-family:標楷體;
    color:red;
    font-size: 9pt;
}
#usage2{
    width:174.3pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    line-height: 12.0pt;
    text-align: center;
    font-family: 標楷體;
}
#license_number1{
    width:85.7pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#license_number2{
    width:458px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:22.75pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt  ;
    font-family:標楷體;   
}
#base_area{
    width:26.45pt;
    border-top:none;
    border-left:solid red 2.25pt;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    margin-top:0cm;
    margin-right:5.65pt;
    margin-bottom:0cm;
    margin-left:5.65pt;
    margin-bottom:.0001pt;
    text-align:center;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#arcade1,#buliding_area1,#total1{
    width:48.45pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#arcade2,#buliding_area2,#total2{
    width:174.3pt;
    border-top:none;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#open_space1,#legal_space1,#construction_rate1{
    width:62.75pt;
    border-top:none;border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:22.75pt;
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#open_space2,#legal_space2,#construction_rate2{
    width:488px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:22.75pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#remark1{
    width:26.45pt;
    border-top:medium none;
    border-left:2.25pt solid red;
    border-bottom:2.25pt solid red;
    border-right:1.0pt solid red;
    height:205px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#remark2{
    vertical-align: top;
    width:870px;
    border-top:medium none;
    border-left:medium none;
    border-bottom:2.25pt solid red;
    border-right:2.25pt solid red;
    height:205px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    line-height:12.0pt;
    font-family:標楷體;
    color:red;
}
#text7{
    margin-top:2.7pt;
    margin-right:0cm;
    margin-bottom:2.7pt;
    margin-left:0cm;
    line-height:14.0pt;
    font-size:10.0pt;
    font-family:標楷體;
    color:red;
}
#table2{
    border-spacing: 0;
    padding: 0;
    width:915px;
    border-collapse:collapse;
    border:medium none;
    height:294;
    border-bottom:solid red 2.25pt;
}
#base_number1{
    width:90.55pt;
    border-top:2.25pt;
    border-left:2.25pt;
    border-bottom:1.0pt;
    border-right:1.0pt;
    border-style:solid;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:17.0pt;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#base_area_down1{
    width:124.85pt;
    border-top:solid red 2.25pt;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:17.0pt;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#buliding_area_down1{
    width:117.3pt;
    border-top:solid red 2.25pt;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:17.0pt;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#legal_area_down1{
    width:117.35pt;
    border-top:solid red 2.25pt;
    border-left:none;
    border-bottom:solid red 1.0pt;
    border-right:solid red 1.0pt;
    padding:0cm 1.4pt 0cm 1.4pt;
    height:17.0pt;
    text-align:center;
    line-height:12.0pt;
    font-size:9.0pt;
    font-family:標楷體;
    color:red;
}
#construction_rate_down1{
    width:311px;
    border-top:2.25pt solid red;
    border-left:medium none;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:17.0pt; 
    padding-left:1.4pt; 
    padding-right:1.4pt;
     padding-top:0cm; 
     padding-bottom:0cm;
     text-align:center;
     line-height:12.0pt;
     font-size:9.0pt;
     font-family:標楷體;
     color:red;
}
#base_number2,#base_number3,#base_number4,#base_number5,#base_number6{
    vertical-align: middle;
    width:90.55pt;
    border-top:medium none;
    border-left:2.25pt solid red;
    border-bottom:1.0pt solid red;
    border-right:1.0pt solid red;
    height:54px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#base_area_down2,#base_area_down3,#base_area_down4,#base_area_down5,#base_area_down6{
    vertical-align: middle;
    width:124.85pt;
    border-top:medium none;
    border-left:1pt solid red;
    border-bottom:1.0pt solid red;
    border-right:1.0pt solid red;
    height:54px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#buliding_area_down2,#buliding_area_down3,#buliding_area_down4,#buliding_area_down5,#buliding_area_down6{
    vertical-align: middle;
    width:117.3pt;
    border-top:medium none;
    border-left:1pt solid red;
    border-bottom:1.0pt solid red;
    border-right:1.0pt solid red;
    height:54px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#legal_area_down2,#legal_area_down3,#legal_area_down4,#legal_area_down5,#legal_area_down6{
    vertical-align: middle;
    width:117.35pt;
    border-top:medium none;
    border-left:1pt solid red;
    border-bottom:1.0pt solid red;
    border-right:1.0pt solid red;
    height:54px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#construction_rate_down2,#construction_rate_down3,#construction_rate_down4,
#construction_rate_down5,#construction_rate_down6{
    vertical-align: middle;
    width:311px;
    border-top:medium none;
    border-left:1pt solid red;
    border-bottom:1.0pt solid red;
    border-right:2.25pt solid red;
    height:54px; 
    padding-left:1.4pt; 
    padding-right:1.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    text-align:center;
    line-height:12.0pt;
    font-family:標楷體;
}
#WordSection1{
    layout-grid: 18.0pt;
    width:687.4pt;
}
#content{
    margin-left:auto; 
    margin-right:auto;
    margin-top: 50px;
    border-collapse: collapse;
    font-size: 20px;   
}
#content_1{
    vertical-align: top;
    
    padding-left:5.4pt;
    padding-right:5.4pt; 
    padding-top:0cm; 
    padding-bottom:0cm;
    
    line-height:43.0pt;
    font-size:24.0pt;
    font-family:標楷體;
}
#LicLosetitle{
    text-align: center;
    line-height:43.0pt;
    font-size:24.0pt;
    font-family:標楷體;
}
.content2{
    text-indent:40.0pt;
    line-height:43.0pt;
    font-size:18.0pt;
    font-family:標楷體;
}
#content3{
    text-indent:100.0pt;
    line-height:43.0pt;
    font-size:18.0pt;
    font-family:標楷體;
}
#content4{
    text-indent:320.0pt;
    line-height:43.0pt;
    font-size:18.0pt;
    font-family:標楷體;

}
.content5{
    text-align:justify;
    text-justify:distribute-all-lines;
    line-height:43.0pt;
    font-size:18.0pt;
    font-family:標楷體;
}
