html, body {height: 100%}
#root {height: 99%}
.marginWrap {
  margin : 10px;
}
.topNav {
  margin: 10px;
}
.App-link {
  color: #61dafb;
}
header {
  /* text-align: center; */
  padding-left: 5%;
  background-color: #0b6754;
}
header img {
  max-height: 120px;
}
header a:hover {
  color: white;
  text-decoration:none;
}
header a {
  color: white;
  text-decoration:none;
}
h1#topH1 {
  margin: 0;
  padding: 20px;
}

img.imgButton {
  width: 100%;
  margin: 10px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
